<template>
	<div>

		<el-card class="filter-container" shadow="never">
			<el-form ref="form" :model="form" :rules="dataRule" label-width="150px">
				<el-form-item label="所属栏目">
					<el-select v-model="form.parentid" placeholder="所属栏目" style="width: 30%;">
						<el-option v-for="item in menuData" :key="item.id" :label="item.menuName" :value="item.id"></el-option>

					</el-select>
				</el-form-item>

				<el-form-item label="菜单名称" prop="menuName">
					<el-input v-model="form.menuName" style="width: 30%;"></el-input>
				</el-form-item>

				<!-- <el-form-item label="英文菜单名称">
					<el-input v-model="form.menuEnname" style="width: 30%;"></el-input>
				</el-form-item>
 -->
				<el-form-item label="是否启用">
					<el-switch v-model="form.menuOpen"></el-switch>
				</el-form-item>

				<el-form-item label="排序" prop="listorder">
					<el-input v-model="form.listorder" style="width: 30%;" onkeyup="value=value.replace(/[^\d]/g,'')" onafterpaste="value=value.replace(/\D/g,'')"></el-input>
                    <font color="red" style="margin-left: 10px">请输入整型数字!!</font>
				</el-form-item>
				<!-- <el-form-item label="SEO标题" style="width: 30%;">
					<el-input  v-model="form.menuSeoTitle"></el-input>
				</el-form-item>
				
				<el-form-item label="SEO关键字" style="width: 30%;">
					<el-input  v-model="form.menuSeoKey"></el-input>
				</el-form-item>
				<el-form-item label="SEO描述" style="width: 30%;">
					<el-input  v-model="form.menuSeoDes"></el-input>
				</el-form-item>
				 -->
				<el-form-item>
					<el-button type="primary" @click="onSubmit">确定</el-button>
					<el-button type="info" @click="gobreak">取消</el-button>
				</el-form-item>
			</el-form>
		</el-card>

	</div>


</template>
<script>
	export default {


		// var data = {
		// 	id: 19,
		// 	"listorder": 50,
		// 	"menuEnname": "ceshiming",
		// 	"menuOpen": 0,
		// 	"menuSeoDes": "SEO描述",
		// 	"menuSeoKey": "SEO关键字",
		// 	"menuSeoTitle": "SEO标题",
		// 	"parentid": 0,
		
		// 	"menuAddress": "http://www.baidu.com",
		// 	"menuContent": "string",
		// 	"menuImg": "url",
		// 	"menuL": "zh-cn",
		// 	"menuListtpl": "string",
		// 	"menuModelid": 0,
		// 	"menuName": "测试名称",
		// 	"menuNewstpl": "string",
		// 	"menuType": 3,
		
		// }
		



		data() {
			return {
				form: {
					id: '',
					menuName: '',
					menuEnname: '',
					parentid: '',
					menuOpen: false,
					listorder: '',
					menuSeoTitle: '',
					menuSeoKey: '',
					menuSeoDes: '',
					menuAddress: '',
					menuContent: '',
					menuImg: '',
					menuL: "zh-cn",
					menuListtpl: '',
					menuModelid: 0,
					menuNewstpl: '',
					menuType: 3
				},
				menuData: [],
				query: [],
                dataRule: {
                    menuName: [
                        { required: true, message: '菜单名称不能为空', trigger: 'blur' }
                    ],
                    listorder: [
                        { required: true, message: '排序不能为空', trigger: 'blur' }
                    ]
                },
			}
		},
		methods: {
			onSubmit() {
				const that=this;
                that.$refs['form'].validate((valid) => {
                    if (valid) {
                        let apiUrl = "";
                        if (this.query.ac == "edit") {
                            //修改逻辑
                            apiUrl = '/admin/menu/edit';
                        } else {
                            delete this.form.id;
                            //添加逻辑
                            this.form.parentid = this.form.parentid === "" ? 0 : this.form.parentid;
                            apiUrl = '/admin/menu/add';
                        }
                            this.form.menuOpen = this.form.menuOpen === true ? '1' : '0';

                        // this.form
                        this.api(apiUrl, that.form).then(res => {
                            if (res.code == 200) {
                                // this.$router.go(-1); //返回上一层
                                that.$router.replace({name:'newsmenulist'})
                            }
                        });
                    }
                })
			},
			// 返回上一页
			gobreak(){
				const that=this;
				this.$router.go(-1);//返回上一层
			},
			
			//获取类型详情
			getType() {
				const that = this;
				that.api('/admin/menu/detail', {
					id: this.query.id
				}).then(res => {
					that.form.parentid = res.data.parentid == 0 ? "" : res.data.parentid;
					if (that.query.ac == "edit") {
						that.form.id = res.data.id;
						that.form.menuName = res.data.menuName;
						that.form.menuEnname = res.data.menuEnname;
						that.form.menuOpen = res.data.menuOpen == 1 ? true : false;
						that.form.listorder = res.data.listorder;
						// that.form.menuSeoTitle = res.data.menuSeoTitle;
						// that.form.menuSeoKey = res.data.menuSeoKey;
						// that.form.menuSeoDes = res.data.menuSeoDes;
					} else if (that.query.ac == "sub") {
						that.form.parentid = that.query.id;
					}
				})
			},
			// 获取列表
			getlist() {
				const that = this;
				that.api('/admin/menu/list').then(res => {
					for (var i = 0; i < res.data.length; i++) {
						if (res.data.menuOpen == '1') {
							res.data.menuOpen = true
						} else {
							res.data.menuOpen = false
						}
					}
					that.menuData = res.data;
					// that.pageNo = res.data.pageNo;
					// this.pageshow = false; //让分页隐藏
					// this.$nextTick(() => { //重新渲染分页
					// 	this.pageshow = true;
					// });
				})
			},
		},
		created() {
			this.query = this.$route.query;
			if (this.query.id) {
				this.getType();
			}
			this.getlist();

		}
	}
</script>

<style scoped lang="less">
</style>
